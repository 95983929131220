<script setup lang="ts"></script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.9 14.83">
    <rect
      x="1"
      y=".95"
      width="10.9"
      height="12.93"
      rx="3.65"
      ry="3.65"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
    <line
      x1="3.34"
      y1="4.1"
      x2="9.57"
      y2="4.1"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
    <line
      x1="3.34"
      y1="8.19"
      x2="9.57"
      y2="8.19"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
    <line
      x1="3.34"
      y1="6.14"
      x2="9.57"
      y2="6.14"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
    <line
      x1="3.34"
      y1="10.23"
      x2="6.45"
      y2="10.23"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
  </svg>
</template>

<style scoped></style>
